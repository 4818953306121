import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import useRichText from "../Hooks/useRichText"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useViewport from "../Hooks/useViewport"
import useSeoQuery from "../queries/useSeoQuery"

const useEstudio = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulEstudio {
        edges {
          node {
            titular
            subtitular
            descripcion {
              raw
            }
            imagen {
              gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return data.allContentfulEstudio.edges[0].node
}

const Estudio = () => {
  const data = useEstudio()
  const seo = useSeoQuery()
  useViewport()
  
  return (
    <Layout>
      <Seo
        title="Estudio"
        lang="es"
        titleSEO={seo.estudioTitleSeo}
        description={seo.estudioDescriptionSeo.estudioDescriptionSeo}
      />
      <div className="Estudio">

        <div className="Estudio--title">
          <h1>{data.titular}</h1>
          <h6>{data.subtitular}</h6>
        </div>

        <div className="Estudio--text">
          <p>{useRichText(data.descripcion)}</p>
        </div>

        <div className="Estudio--img">
          <GatsbyImage image={getImage(data.imagen)} alt="Estudio" />
        </div>

      </div>
    </Layout>
  )
}

export default Estudio

import { graphql, useStaticQuery } from "gatsby"

const useSeoQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSeo {
        edges {
          node {
            estudioDescriptionSeo {
              estudioDescriptionSeo
            }
            estudioTitleSeo
            homeDescriptionSeo {
              homeDescriptionSeo
            }
            homeTitleSeo
            proyectosDescriptionSeo {
              proyectosDescriptionSeo
            }
            proyectosTitleSeo
            reportajesDescriptionSeo {
              reportajesDescriptionSeo
            }
            reportajesTitleSeo
          }
        }
      }
    }
  `)

  return data.allContentfulSeo.edges[0].node
}

export default useSeoQuery